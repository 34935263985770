@import 'lg-variables';
@import 'lg-mixins';
.lg-outer {
    // reset transition duration
    &.lg-css3.lg-zoom-dragging {
        .lg-item.lg-complete.lg-zoomable {
            .lg-img-wrap,
            .lg-image {
                @include transition-duration(0ms !important);
            }
        }
    }
    &.lg-use-transition-for-zoom {
        .lg-item.lg-complete.lg-zoomable {
            .lg-img-wrap {
                will-change: transform;
                @include transitionCustom(
                    transform $zoom-transition-duration
                        cubic-bezier(0.12, 0.415, 0.01, 1.19) 0s
                );
            }
        }
        &.lg-zoom-drag-transition {
            .lg-item.lg-complete.lg-zoomable {
                .lg-img-wrap {
                    will-change: transform;
                    @include transitionCustom(
                        transform 0.8s cubic-bezier(0, 0, 0.25, 1) 0s
                    );
                }
            }
        }
    }

    .lg-item.lg-complete.lg-zoomable {
        .lg-img-wrap {
            @include translate3d(0, 0, 0);
            @include backface-visibility(hidden);
        }

        .lg-image,
        .lg-dummy-img {
            // Translate required for zoom
            @include scale3d(1, 1, 1);
            @include transitionCustom(
                transform $zoom-transition-duration
                    cubic-bezier(0.12, 0.415, 0.01, 1.19) 0s,
                opacity 0.15s !important
            );
            @include backface-visibility(hidden);

            &.no-transition {
                transition: none !important;
            }
            &.reset-transition {
                transform: scale3d(1, 1, 1) translate3d(-50%, -50%, 0px) !important;
                max-width: none !important;
                max-height: none !important;
                top: 50% !important;
                left: 50% !important;
            }
            &.reset-transition-x {
                transform: scale3d(1, 1, 1) translate3d(-50%, 0, 0px) !important;
                top: 0 !important;
                left: 50% !important;
                max-width: none !important;
                max-height: none !important;
            }
            &.reset-transition-y {
                transform: scale3d(1, 1, 1) translate3d(0, -50%, 0px) !important;
                top: 50% !important;
                left: 0% !important;
                max-width: none !important;
                max-height: none !important;
            }
        }
    }
}

.lg-icon {
    // zoom buttons
    &.lg-zoom-in {
        &:after {
            content: '\e311';
        }
        .lg-actual-size & {
            opacity: 1;
            pointer-events: auto;
        }
    }

    &.lg-actual-size {
        font-size: 20px;
        &:after {
            content: '\e033';
        }
    }

    &.lg-zoom-out {
        opacity: 0.5;
        pointer-events: none;

        &:after {
            content: '\e312';
        }

        .lg-zoomed & {
            opacity: 1;
            pointer-events: auto;
        }
    }
}
.lg-outer {
    &[data-lg-slide-type='video'],
    &[data-lg-slide-type='iframe'],
    &.lg-first-slide-loading {
        .lg-zoom-in,
        .lg-actual-size,
        .lg-zoom-out {
            opacity: $lg-toolbar-icon-disabled-opacity;
            pointer-events: none;
        }
    }
}
